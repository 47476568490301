import React from 'react';

import GeneralAnchor from '../general-anchor/general-anchor.component';

const CalculatorBox = ({ title, links = [], color = '#3E505D' }) => (
  <div className='calculator-box col' style={{ backgroundColor: color }}>
    <h2 className='block-title'>{title}</h2>
    {links.length
      ? (
        <ul>
          {links.map((linkProps) => (
            <>
              <li key={linkProps.title}>
                <GeneralAnchor additionalClass='calculator-box__anchor' {...linkProps} >
                  {linkProps.title}<span></span>
                </GeneralAnchor>
              </li>
              {linkProps.desc ? <p className='calculator-box__description'>{linkProps.desc}</p> : ''}
            </>
          ))}
        </ul>
      )
      : null
    }
  </div>
);

export default CalculatorBox;